import { autoserializeAs, autoserializeAsArray, autoserializeAsJson, autoserializeAsObjectMap } from 'dcerialize';

export class CustomerDomainList {
  /**
   * Customer name and domain list
   */
  @autoserializeAsArray(() => CustomerDomainResponse) items: CustomerDomainResponse[];

  /**
   * Total of customers
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: CustomerDomainResponse[], total: number) {
    this.items = items;
    this.total = total;
  }
}

export class CustomerDomainResponse {
  /**
   * Customer id
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * Customer name
   */
  @autoserializeAs(() => String) name: string;

  /**
   * Customer domain
   */
  @autoserializeAs(() => String) domain: string;

  constructor(id: number, name: string, domain: string) {
    this.id = id;
    this.name = name;
    this.domain = domain;
  }
}

export class CallbellCustomerConfig {
  /**
   * Callbell Token
   */
  @autoserializeAs(() => String) token: string;

  /**
   * Callbell UUID
   */
  @autoserializeAs(() => String) uuid: string;

  constructor(token: string, uuid: string) {
    this.token = token;
    this.uuid = uuid;
  }
}

export class CustomerConfig {
  /**
   * Customer domain
   */
  @autoserializeAs(() => String) domain: string;

  /**
   * Customer name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * Customer logoUrlBase
   */
  @autoserializeAs(() => String) logoUrlBase?: string;

  /**
   * Customer callbellToken
   */
  @autoserializeAs(() => CallbellCustomerConfig) callbellConfig?: CallbellCustomerConfig;

  constructor(domain: string, name?: string, logoUrlBase?: string, callbellConfig?: CallbellCustomerConfig) {
    this.domain = domain;
    this.name = name;
    this.logoUrlBase = logoUrlBase;
    this.callbellConfig = callbellConfig;
  }
}

export enum CustomerModuleKey {
  /* eslint-disable @typescript-eslint/naming-convention */
  WEBINARS = 'WEBINARS',
  SALESIQ = 'SALESIQ',
  ONLYFREECOURSESWEB = 'ONLY_FREE_COURSES_WEB',
  ONLYFREECOURSESAPP = 'ONLY_FREE_COURSES_APP',
  MYLXPACADEMIES = 'MYLXP_ACADEMIES',
  MYLXPFACULTIES = 'MYLXP_FACULTIES',
  ENABLEDSSO = 'ENABLED_SSO',
  FACEBOOKSSO = 'FACEBOOK_SSO',
  GOOGLESSO = 'GOOGLE_SSO',
  APPLESSO = 'APPLE_SSO',
  LINKEDINSSO = 'LINKEDIN_SSO',
  TUTORLXP = 'TUTOR_LXP',
  TUTORAI = 'TUTOR_AI',
  PAYMENT_MANAGEMENT = 'PAYMENT_MANAGEMENT'
  /* eslint-enable @typescript-eslint/naming-convention */
}

export type CustomerModulesDict = Record<CustomerModuleKey, CustomerModule>;

export class CustomerModule {
  /**
   * Module enabled
   */
  @autoserializeAs(() => Boolean) enabled: boolean;

  /**
   * Module config
   */
  @autoserializeAsJson() config?: any;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(enabled: boolean, config?: any) {
    this.enabled = enabled;
    this.config = config;
  }
}

export class CustomerModules {
  /**
   * Customer modules
   */
  @autoserializeAsObjectMap(() => CustomerModule) modules: CustomerModulesDict;

  constructor(modules: CustomerModulesDict) {
    this.modules = modules;
  }
}
